<template>
  <div class="SNOWS-preview-main flow-form-main">
    <div class="SNOWS-common-page-header">
      <el-page-header @back="goBack" content="" />
      <el-steps :active="active" finish-status="success" simple
        :class="dataForm.dataType!=2?'elsteps':'steps'">
        <el-step :title="$t(`cip.plat.sys.dataInterface.title.basicInfo`)"></el-step>
        <el-step :title="$t(`cip.plat.sys.dataInterface.title.dataConf`)"></el-step>
        <el-step v-if="dataForm.dataType!=2" :title="$t('cip.plat.sys.dataInterface.title.dataConf')"></el-step>
      </el-steps>
      <div class="options">
        <el-button :disabled="active <= 0" @click="handlePrevStep">{{$t('cip.plat.sys.dataInterface.field.prev')}}
        </el-button>
        <el-button :disabled="dataForm.dataType!=2?active >= 2:active >= 1" @click="handleNextStep">
          {{$t('cip.plat.sys.dataInterface.field.next')}}
        </el-button>
        <el-button type="primary" :loading="btnLoading"
          :disabled="dataForm.dataType!=2?active < 2:active < 1" @click="dataFormSubmit()">
          {{$t('cip.cmn.btn.defBtn')}}</el-button>
        <el-button @click="goBack">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      </div>
    </div>
    <el-form ref="dataForm" :model="dataForm" :rules="dataRule" v-loading="formLoading"
      label-width="200px" v-if="active === 0" style="height: 100%">
      <el-row>
        <el-col :span="14" :offset="5" class="baseInfo mt-20">
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.fullName')" prop="fullName">
            <el-input v-model="dataForm.fullName" :placeholder="$t('cip.plat.sys.dataInterface.field.fullName')" maxlength="50" />
          </el-form-item>
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.enCode')" prop="enCode">
            <el-input v-model="dataForm.enCode" :placeholder="$t('cip.plat.sys.dataInterface.field.enCode')" maxlength="50" />
          </el-form-item>
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.categoryId')" prop="categoryId">
            <el-select v-model="dataForm.categoryId" :placeholder="$t('cip.plat.sys.dataInterface.field.categoryId')">
              <el-option
                v-for="item in dataOptions"
                :key="item.id"
                :label="item.dictValue"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="ipAddress" v-if="dataForm.checkType===2">
            <el-input v-model="dataForm.ipAddress" :placeholder="$t('cip.plat.sys.dataInterface.msg.ipAddress')" />
          </el-form-item>
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.type')"  prop="dataType">
            <el-radio-group v-model="dataForm.dataType" @change="onDataTypeChange">
              <el-radio :label="2">{{$t('cip.plat.sys.dataInterface.field.staticData')}} </el-radio>
              <el-radio :label="1">{{$t('cip.plat.sys.dataInterface.field.SQLOperations')}} </el-radio>
              <el-radio :label="3">{{$t('cip.plat.sys.dataInterface.field.APIOperations')}} </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.action')" prop="requestMethod" v-if="dataForm.dataType===1">
            <el-radio-group v-model="dataForm.requestMethod" @change="onMethodChange($event,'sql')">
              <el-radio label="3">{{$t('cip.plat.sys.dataInterface.field.Query')}}</el-radio>
              <el-radio label="1">{{$t('cip.plat.sys.dataInterface.field.Add')}}</el-radio>
              <el-radio label="2">{{$t('cip.plat.sys.dataInterface.field.Modify')}}</el-radio>
              <el-radio label="4">{{$t('cip.plat.sys.dataInterface.field.Delete')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.sort')" prop="sortCode">
            <el-input-number :min="0" :max="999999" v-model="dataForm.sortCode"
              controls-position="right" />
          </el-form-item>
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.status')" prop="enabledMark">
            <el-switch v-model="dataForm.enabledMark" :active-value="1" :inactive-value="0" />
          </el-form-item>
          <el-form-item :label="$t('cip.plat.sys.dataInterface.field.description')" prop="description">
            <el-input v-model="dataForm.description" type="textarea" :rows="3" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="config" v-if="active === 1 && dataForm.dataType === 1">
      <div class="tableData">
        <el-select v-model="dataForm.dbLinkId" filterable :placeholder="$t('cip.plat.sys.dataInterface.field.changeDB')" style="width: 100%"
          @change="handleSelectTable">
            <el-option v-for="item in dbOptions" :key="item.id" :label="item.linkName"
              :value="item.id" />
        </el-select>
        <div class="box">
          <el-tree :data="treeData" node-key="index" :props="defaultProps"
            @node-click="handleNodeClick" />
        </div>
      </div>
      <div class="detail">
        <div class="middle-pane">
          <div class="right-pane-list">
            <div class="cap">
              <span slot="label">{{ $t('cip.plat.sys.dataInterface.field.SQL') }}
                <el-tooltip :content="$t('cip.plat.sys.dataInterface.field.SQL')" placement="top">
                  <a class="el-icon-warning-outline"></a>
                </el-tooltip>
              </span>
              <div style="float:right;cursor:pointer">
                <el-dropdown>
                  <span class="el-dropdown-link" title="111">
                    {{ $t('cip.plat.sys.dataInterface.field.systemVariables') }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled>{{ $t('cip.plat.sys.dataInterface.msg.msg') }}</el-dropdown-item>
                    <el-dropdown-item divided></el-dropdown-item>
                    <el-dropdown-item v-for="(item,index) in sysVariableList" :key="index">
                      <div @click="handleSysNodeClick(item.value)">
                        <span>{{ item.value }}</span>
                        <span
                          style="float: right; color: #8492a6;padding-left: 10px;">{{ item.tips }}</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="list">
              <el-form ref="dataForm" :model="dataForm" :rules="dataRule" label-width="100px"
                style="padding-top: 0px;">
                <el-form-item label-width="0" prop="query">
                  <div class="sql-box">
                    <SQLEditor v-model="dataForm.query" :options="sqlOptions" ref="SQLEditorRef" />
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="right-pane">
        <div class="right-pane-list">
          <div class="cap">
            <span>
              <el-tooltip :content="$t('cip.plat.sys.dataInterface.field.reviceMeth')" placement="top">
                <a class="el-icon-warning-outline"></a>
              </el-tooltip>
            </span>
          </div>
          <div class="list">
            <el-table :data="requestParameters" ref="dragTable" row-key="id" size='mini'
              height="100%">
              <el-table-column align="center" :label="$t('cip.plat.sys.dataInterface.field.drag')" width="50">
                <template>
                  <i class="drag-handler icon-ym icon-ym-darg" style="cursor: move;font-size:20px"
                    :title="$t('cip.plat.sys.dataInterface.field.clickDrag')" />
                </template>
              </el-table-column>
              <el-table-column prop="field" :label="$t('cip.plat.sys.dataInterface.field.paramName')">
                <template slot-scope="scope">
                  <p @click="handleItemClick(scope.row)" style="cursor:pointer">
                    <span class="required-sign">{{scope.row.required?'*':''}}</span>
                    {{scope.row.field}}{{scope.row.fieldName?'('+scope.row.fieldName+')':''}}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="dataType" :label="$t('cip.plat.sys.dataInterface.field.paramType')" width="70">
                <template slot-scope="scope">
                  <span v-if="scope.row.dataType === 'varchar'">{{ $t('cip.plat.sys.dataInterface.field.char') }}</span>
                  <span v-if="scope.row.dataType === 'int'">{{ $t('cip.plat.sys.dataInterface.field.int') }}</span>
                  <span v-if="scope.row.dataType === 'datetime'">{{ $t('cip.plat.sys.dataInterface.field.date') }}</span>
                  <span v-if="scope.row.dataType === 'decimal'">{{ $t('cip.plat.sys.dataInterface.field.decimal') }}</span>
                  <span v-if="scope.row.dataType === 'bigint'">{{ $t('cip.plat.sys.dataInterface.field.bigint') }}</span>
                  <span v-if="scope.row.dataType === 'text'">{{ $t('cip.plat.sys.dataInterface.field.text') }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('cip.plat.sys.dataInterface.field.make')" width="70">
                <template slot-scope="scope">
                  <el-button type="text" @click="addOrUpdateHandle(scope.row)"
                    icon="el-icon-edit-outline"></el-button>
                  <el-button type="text" class="SNOWS-table-delBtn" icon="el-icon-delete"
                    @click="removeParameter(scope.$index)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="table-actions" @click="addOrUpdateHandle()">
            <el-button type="text" icon="el-icon-plus">{{ $t('cip.plat.sys.dataInterface.field.addParam') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="jsStaticData" v-if="active === 2">
      <div class="json-box">
        <SNOWSCodeEditor v-model="text" :options="options" ref="CodeEditor" />
      </div>
      <div class="jsTips">
        <p>{{ $t('cip.plat.sys.dataInterface.msg.JSmsg') }}</p>
        <p>{{ $t('cip.plat.sys.dataInterface.msg.JSmsg1') }}</p>
      </div>
    </div>
    <div class="staticData" v-if="active === 1 && dataForm.dataType === 2">
      <el-form ref="dataForm" :model="dataForm" :rules="dataRule" label-width="100px">
        <el-form-item label-width="0" prop="query">
          <div class="json-box">
            <JSONEditor v-model="dataForm.query" :options="jsonOptions" ref="JSONEditorRef" />
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-form ref="dataForm" :model="dataForm" :rules="dataRule" label-width="110px"
      v-if="active === 1 && dataForm.dataType === 3">
      <el-row>
        <el-col :span="14" :offset="5" class="mt-20 baseInfo">
          <snows-form-tip-item :label="$t('cip.plat.sys.dataInterface.field.interfaceType')" prop="requestMethod">
            <el-radio-group v-model="dataForm.requestMethod" @change="onMethodChange($event,'api')">
              <el-radio label="6">GET</el-radio>
              <el-radio label="7">POST</el-radio>
            </el-radio-group>
          </snows-form-tip-item>
          <snows-form-tip-item :label="$t('cip.plat.sys.dataInterface.field.interfacePath')" prop="path">
            <el-input v-model="dataForm.path" :placeholder="$t('cip.plat.sys.dataInterface.field.interfacePath')">
              <el-button slot="append" class="el-icon-plus" @click="addHeaders()">{{ $t('cip.plat.sys.dataInterface.field.addHeader')}}
              </el-button>
            </el-input>
            <el-row v-for="(item, index) in requestHeaders" :key="item.index" class="mt-10">
              <el-col :span="10">
                <el-autocomplete v-model="item.field" :fetch-suggestions="querySearch"
                  placeholder="key" clearable style="width:100%" />
              </el-col>
              <el-col :span="10" :offset="1">
                <el-input v-model="item.defaultValue" placeholder="value" clearable />
              </el-col>
              <el-col :span="2" :offset="1">
                <el-button type="danger" icon="el-icon-close" @click="removeHeaders(index)">
                </el-button>
              </el-col>
            </el-row>
          </snows-form-tip-item>
          <snows-form-tip-item :label="$t('cip.plat.sys.dataInterface.field.param')" :tip-label="$t('cip.plat.sys.dataInterface.field.reviceMeth')">
            <el-button @click="addOrUpdateHandle()" class="el-icon-plus" size="mini">{{ $t('cip.plat.sys.dataInterface.field.addParam') }}
            </el-button>
          </snows-form-tip-item>
          <div class="parameterList">
            <el-table :data="requestParameters" ref="dragTable" row-key="id" size='mini'>
              <el-table-column align="center" :label="$t('cip.plat.sys.dataInterface.field.drag')" width="50">
                <template>
                  <i class="drag-handler icon-ym icon-ym-darg" style="cursor: move;font-size:20px"
                    :title="$t('cip.plat.sys.dataInterface.field.clickDrag')" />
                </template>
              </el-table-column>
              <el-table-column prop="field" :label="$t('cip.plat.sys.dataInterface.field.paramName')">
                <template slot-scope="scope">
                  <p>
                    <span class="required-sign">{{scope.row.required?'*':''}}</span>
                    {{scope.row.field}}{{scope.row.fieldName?'('+scope.row.fieldName+')':''}}
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="dataType" :label="$t('cip.plat.sys.dataInterface.field.paramType')" width="70">
                <template slot-scope="scope">
                  <span v-if="scope.row.dataType === 'varchar'">{{ $t('cip.plat.sys.dataInterface.field.char') }}</span>
                  <span v-if="scope.row.dataType === 'int'">{{ $t('cip.plat.sys.dataInterface.field.int') }}</span>
                  <span v-if="scope.row.dataType === 'datetime'">{{ $t('cip.plat.sys.dataInterface.field.date') }}</span>
                  <span v-if="scope.row.dataType === 'decimal'">{{ $t('cip.plat.sys.dataInterface.field.decimal') }}</span>
                  <span v-if="scope.row.dataType === 'bigint'">{{ $t('cip.plat.sys.dataInterface.field.bigint') }}</span>
                  <span v-if="scope.row.dataType === 'text'">{{ $t('cip.plat.sys.dataInterface.field.text') }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="defaultValue" :label="$t('cip.plat.sys.dataInterface.field.defaultValue')" />
              <el-table-column :label="$t('cip.plat.sys.dataInterface.field.make')" width="70">
                <template slot-scope="scope">
                  <el-button type="text" @click="addOrUpdateHandle(scope.row)"
                    icon="el-icon-edit-outline"></el-button>
                  <el-button type="text" class="SNOWS-table-delBtn" icon="el-icon-delete"
                    @click="removeParameter(scope.$index)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <FieldForm v-show="fieldFormVisible" ref="fieldForm" @addParameter="addParameter" />
    <form-script :visible.sync="formScriptVisible" :value="this.dataForm.dataProcessing"
      @updateScript="updateScript" />
  </div>
</template>

<script>
import {
  getDataInterfaceInfo,
  createDataInterface,
  updateDataInterface, getTreeData,
} from '@/api/systemData/dataInterface'
import { getDataSourceListAll } from '@/api/systemData/dataSource'
import { DataModelList } from '@/api/systemData/dataModel'
import SQLEditor from '@/components/SNOWSEditor/monaco'
import JSONEditor from '@/components/SNOWSEditor/monaco'
import FieldForm from './FieldForm'
import FormScript from './FormScript'
import { deepClone } from '@/util/util'
import Sortable from 'sortablejs'
import SNOWSCodeEditor from '@/components/SNOWSEditor/monaco'
import {$t} from "@/lang";
const defaultDataHandler = $t('cip.plat.sys.dataInterface.msg.data')

export default {
  components: {
    SQLEditor,
    JSONEditor,
    FieldForm,
    FormScript,
    SNOWSCodeEditor
  },
  data() {
    return {
      active: 0,
      treeLoading: false,
      formLoading: false,
      btnLoading: false,
      fieldFormVisible: false,
      formScriptVisible: false,
      selectData: [],
      dataOptions:[],
      sqlOptions: {
        language: 'sql'
      },
      jsonOptions: {
        language: 'json'
      },
      dataForm: {
        fullName: '',
        enCode: '',
        categoryId: '',
        dbLinkId: '',
        dataType: 2,
        checkType: 0,
        ipAddress: '',
        requestHeaders: '',
        requestMethod: '1',
        responseType: 'json',
        sortCode: 0,
        enabledMark: 1,
        description: '',
        dataProcessing: '',
        requestParameters: '',
        query: ''
      },
      restaurants: [
        { "value": "Postman-Token" },
        { "value": "Host" },
        { "value": "User-Agent" },
        { "value": "Accept" },
        { "value": "Accept-Encoding" },
        { "value": "Connection" }
      ],
      requestHeaders: [],
      requestParameters: [],
      sqlRequestMethod: '3',
      apiRequestMethod: '6',
      dbOptions: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: function (data, node) {
          return data.table_name + '(' + data.table_comment + ')'
        },
      },
      activeName: 'query',
      dataRule: {
        fullName: [
          {
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.dataInterface.field.fullName"),
            trigger: 'blur'
          },
          {
            pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
            message: $t("cip.cmn.rule.deleteWarning"),
          }
        ],
        enCode: [
          {
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.dataInterface.field.enCode"),
            trigger: 'blur'
          },
          {
            pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
            message: $t("cip.cmn.rule.deleteWarning"),
          }
        ],
        categoryId: [
          {
            required: true,
            message: $t("cip.cmn.rule.selectWarning") + $t("cip.plat.sys.dataInterface.field.categoryId"),
            trigger: 'blur'
          }
        ],
        path: [
          {
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.dataInterface.field.interfacePath"),
            trigger: 'blur'
          }
        ],
        query: [
          {
            required: true,
            message: $t("cip.plat.sys.dataInterface.msg.statement"),
            trigger: 'blur'
          }
        ]
      },
      sysVariableList: [
        { value: '@user', tips: $t("cip.plat.sys.dataInterface.field.user") },
        { value: '@organization', tips: $t("cip.plat.sys.dataInterface.field.organization") },
        { value: '@currentOrganizationAndSuborganization', tips: $t("cip.plat.sys.dataInterface.field.currentOrganizationAndSuborganization") },
      ],
      text: '',
      options: {
        language: 'javascript'
      },
    }
  },
  mounted() {
    this.getTree()
  },
  methods: {
    handleSelectTable(val){
      DataModelList(val).then(res => {
        this.treeData = res.data.data
      })
    },
    // 获取数据连接列表数据
    getTree(){
      getTreeData().then(res=> {
        this.dataOptions = res.data.data;
      })
    },
    goBack() {
      this.$emit('close')
    },
    init(id, categoryId) {
      Object.assign(this.$data, this.$options.data())
      this.active = 0
      this.dataForm.id = id || ''
      this.formLoading = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        // 获取分类
        // this.$store.dispatch('base/getDictionaryData', { sort: 'DataInterfaceType' }).then((res) => {
        //   this.selectData = res
        // })
        // 获取数据库
        getDataSourceListAll().then(res => {
          const list = res.data.data || []
          if (list.length == 0) {
            this.$message.warning(this.$t("cip.plat.sys.dataInterface.msg.dataSource"))
            // this.$message.warning(this.$t("cip.plat.sys.dataInterface.msg.dataSource"))
            return false
          }
          this.dbOptions = list
          if (this.dataForm.id) {
            this.getFormData()
          } else {
            this.dataForm.categoryId = categoryId
            this.formLoading = false
          }
        })
      })
    },
    getFormData() {
      getDataInterfaceInfo(this.dataForm.id).then(res => {
        this.dataForm = res.data.data
        this.dataForm.query = this.resovle(res.data.data.query)
        this.dataForm.dataProcessing = this.resovle(res.data.data.dataProcessing)
        if (res.data.data.requestParameters) this.requestParameters = JSON.parse(res.data.data.requestParameters) || []
        if (res.data.data.requestHeaders) this.requestHeaders = JSON.parse(res.data.data.requestHeaders) || []
        if (res.data.data.dataType === 1) this.sqlRequestMethod = this.dataForm.requestMethod
        if (res.data.data.dataType === 3) this.apiRequestMethod = this.dataForm.requestMethod
        this.formLoading = false
      })
    },
    onDataTypeChange(val) {
      if (val === 1) {
        this.dataForm.requestMethod = this.sqlRequestMethod
      } else if (val === 3) {
        this.dataForm.requestMethod = this.apiRequestMethod
      } else {
        this.dataForm.requestMethod = ''
      }
      this.requestParameters = []
    },
    onMethodChange(val, key) {
      this[key + 'RequestMethod'] = val
    },
    getTableList(id) {
      this.treeLoading = true
      DataModelList(id).then(res => {
        this.treeData = res.data.data
        this.treeLoading = false
      })
    },
    handleNodeClick(data) {
      this.$refs.SQLEditorRef && this.$refs.SQLEditorRef.insert(data.table)
    },
    handleSysNodeClick(data) {
      this.$refs.SQLEditorRef && this.$refs.SQLEditorRef.insert(data)
    },
    handlePrevStep() {
      this.active -= 1
      if (this.active == 0) {
        this.$refs['dataForm'].clearValidate()
      }

    },
    handleNextStep() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (this.active < 2) {
            this.active += 1
            // SQL操作
            if (this.dataForm.dataType === 1) {
              if(this.dataForm.dbLinkId){
                this.getTableList(this.dataForm.dbLinkId)
              }
              this.$refs.SQLEditorRef && this.$refs.SQLEditorRef.changeEditor({
                value: this.dataForm.query,
                options: this.sqlOptions
              })
            }
            //数据处理
            if (this.active == 2 && this.dataForm.dataType != 2) {
              if (!this.dataForm.dataProcessing) this.dataForm.dataProcessing = defaultDataHandler
              this.text = this.dataForm.dataProcessing
              this.$nextTick(() => {
                this.$refs.CodeEditor && this.$refs.CodeEditor.changeEditor({
                  value: this.text,
                  options: this.options
                })
              })
            }
            // 静态数据
            if (this.dataForm.dataType === 2) {
              this.text = this.dataForm.dataProcessing
              this.$refs.JSONEditorRef && this.$refs.JSONEditorRef.changeEditor({
                value: this.text,
                options: this.jsonOptions
              })
            } else {
              if (this.active == 1) {
                this.$nextTick(() => {
                  this.setSort()
                })
              }
            }
          }
        }
      })
    },
    querySearch(queryString, cb) {
      const restaurants = this.restaurants;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    removeHeaders(index) {
      this.requestHeaders.splice(index, 1)
    },
    addHeaders() {
      this.requestHeaders.push({
        field: '',
        defaultValue: ''
      })
    },
    removeParameter(index) {
      this.$confirm(this.$t('cip.plat.sys.dataInterface.msg.delParam'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
      // this.$confirm('此操作删除该参数, 是否继续?', '提示', {
      //   type: 'warning'
      // })
      .then(() => {
        this.requestParameters.splice(index, 1)
      }).catch(() => { });
    },
    addParameter(type, item) {
      this.fieldFormVisible = false
      if (type === 'add') {
        this.requestParameters.push(deepClone(item))
      } else {
        for (let i = 0; i < this.requestParameters.length; i++) {
          if (item.id === this.requestParameters[i].id) {
            this.$set(this.requestParameters, i, deepClone(item))
            break
          }
        }
      }
    },
    addOrUpdateHandle(item) {
      this.fieldFormVisible = true
      this.$nextTick(() => {
        this.$refs.fieldForm.init(item ? JSON.parse(JSON.stringify(item)) : null, this.requestParameters)
      })
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
        setData: function (dataTransfer) {
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.requestParameters.splice(evt.oldIndex, 1)[0]
          this.requestParameters.splice(evt.newIndex, 0, targetRow)
        },
        handle: '.drag-handler',
      })
    },
    editFunc() {
      if (!this.dataForm.dataProcessing) this.dataForm.dataProcessing = defaultDataHandler
      this.$nextTick(() => {
        this.formScriptVisible = true
      })
    },
    updateScript(data) {
      this.dataForm.dataProcessing = data
    },
    handleItemClick(item) {
      if (!item.field) return
      this.$refs.SQLEditorRef.insert('{' + item.field + '}')
    },
    replaceStr(str){
      return str.replaceAll(">", "&gt;").replaceAll("<","&lt;");
    },
    resovle(str){
      return str.replaceAll("&gt;",">").replaceAll("&lt;","<");
    },
    dataFormSubmit() {
      if (this.active == 2) {
        this.btnLoading = true
        this.dataForm.dataProcessing = this.text
        this.dataForm.requestHeaders = JSON.stringify(this.requestHeaders)
        this.dataForm.requestParameters = JSON.stringify(this.requestParameters)
        this.dataForm.dataProcessing = this.replaceStr(this.dataForm.dataProcessing)
        this.dataForm.query = this.replaceStr(this.dataForm.query)
        console.log(this.dataForm.dataProcessing)
        const formMethod = this.dataForm.id ? updateDataInterface : createDataInterface
        formMethod(this.dataForm).then(res => {
          this.$message({
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
            type: 'success',
            onClose: () => {
              this.btnLoading = false
              this.$emit('close', true)
            }
          })
        }).catch(() => {
          this.btnLoading = false
        })
      } else {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.btnLoading = true
            this.dataForm.requestHeaders = JSON.stringify(this.requestHeaders)
            this.dataForm.requestParameters = JSON.stringify(this.requestParameters)
            this.dataForm.requestMethod = '5';
            this.dataForm.dataProcessing = this.replaceStr(this.dataForm.dataProcessing)
            this.dataForm.query = this.replaceStr(this.dataForm.query)
            console.log(this.dataForm.query)
            const formMethod = this.dataForm.id ? updateDataInterface : createDataInterface
            formMethod(this.dataForm).then(res => {
              this.$message({
                message: this.$t('cip.cmn.msg.success.operateSuccess'),
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.btnLoading = false
                  this.$emit('close', true)
                }
              })
            }).catch(() => {
              this.btnLoading = false
            })
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
.jsTips {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  font-size: 14px;
  line-height: 24px;
  color: #5e6d82;
}
.jsStaticData {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 10px;
  .json-box {
    flex: 1;
  }
}
.monaco-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.elsteps {
  width: 506px;
  padding: 6px 20px;
  background: #fff;
  justify-items: flex-start;
}
.steps {
  width: 318px;
  padding: 6px 20px;
  background: #fff;
  justify-items: flex-start;
}
.flow-form-main {
  ::v-deep .main {
    padding: 0 !important;
  }
  ::v-deep .el-tabs__header {
    padding: 0;
    margin-bottom: 0;
    .el-tabs__item {
      line-height: 50px;
    }
  }
  .config {
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .tableData {
      flex-shrink: 0;
      width: 350px;
      .box {
        margin-top: 8px;
        border-radius: 4px;
        height: calc(100% - 40px);
        border: 1px solid #dcdfe6;
        overflow: auto;
        overflow-x: hidden;
        ::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
          padding: 0;
        }
      }
    }

    .detail {
      flex: 1;
      margin: 0 10px;
      margin-top: -10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .top-box {
        display: flex;
        .main-box {
          flex: 1;
          margin-right: 18px;
        }
      }
      .sql-box {
        border-top: 1px solid #dcdfe6;
        // border-bottom: 1px solid #dcdfe6;
        height: calc(100vh - 258px);
        overflow: auto;
      }
      .tips {
        padding: 8px 0;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #50bfff;

        p {
          padding: 8px 0 8px 20px;
        }

        .tips-content {
          display: flex;
          flex-wrap: wrap;

          .tips-content-item {
            display: inline-block;
            padding-left: 20px;
            line-height: 30px;
            color: #5e6d82;
            width: 50%;
            font-size: 14px;
            display: flex;
            align-items: center;

            span {
              cursor: pointer;
              padding-right: 2px;
            }
          }
        }
      }
    }
  }
  .staticData {
    flex: 1;
    .json-box {
      height: calc(100vh - 210px);
    }
  }
  .parameterList {
    padding-left: 110px;
    margin-bottom: 18px;
    ::v-deep .el-icon-edit-outline,
    ::v-deep .el-icon-delete {
      font-size: 16px;
    }
  }
  .middle-pane {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    margin-top: 10px;
    .right-pane-list {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      overflow: hidden;
      .cap {
        height: 36px;
        line-height: 36px;
        display: flex;
        color: #606266;
        font-size: 14px;
        padding: 0 10px;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
      }
      .table-actions {
        flex-shrink: 0;
      }
      .list {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      ::v-deep .el-icon-edit-outline,
      ::v-deep .el-icon-delete {
        font-size: 16px;
      }
    }
    .right-pane-btn {
      flex-shrink: 0;
      .el-button {
        width: 100%;
      }
    }
  }
  .right-pane {
    width: 350px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    height: calc(100% + 9px);
    // margin-top: 10px;
    overflow: hidden;

    .right-pane-list {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      overflow: hidden;
      .cap {
        height: 38px;
        line-height: 38px;
        display: flex;
        color: #606266;
        font-size: 14px;
        padding: 0 10px;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
      }
      .table-actions {
        flex-shrink: 0;
      }
      .list {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      ::v-deep .el-icon-edit-outline,
      ::v-deep .el-icon-delete {
        font-size: 16px;
      }
    }
    .right-pane-btn {
      flex-shrink: 0;
      .el-button {
        width: 100%;
      }
    }
  }
}
</style>
